module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134923604-6","head":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://ghost-pi.local:2368","contentApiKey":"60fe88853fc34f219c7f35053a"},"production":{"apiUrl":"http://ghost-pi.local:2368","contentApiKey":"60fe88853fc34f219c7f35053a"}},"siteConfig":{"siteUrl":"https://westernkingdoms.gorestrepeat.com","googleAnalyticsId":"UA-134923604-6","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Western Kingdoms","siteDescriptionMeta":"A generic fiction blog written by two brothers","shortTitle":"Western Kingdoms","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info","navigation":[{"label":"About","url":"/wk-about/"},{"label":"Current Project","url":"/wk-current-projects/"},{"label":"Contact Us","url":"/contact-us/"}],"customMap":{},"customFilter":{}},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":true,"overrideOS":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
